import React from "react";
import { StaticQuery, graphql } from "gatsby";

export default ({ children }) => (
  <StaticQuery
    query={query}
    render={({ site, homepage }) => {
      return children({ site, homepage });
    }}
  />
);
const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
      enableSplashPage
      splashLottieFile {
        asset {
          url
        }
      }
    }
    homepage: sanityHomePage {
      introLottieMobile {
        asset {
          url
        }
      }
      introLottieDesktop {
        asset {
          url
        }
      }
      introTitleCopy
      _rawAboutCopy
      aboutButtonCopy
      introBackgroundVideo {
        asset {
          url
        }
      }
      clientsTitleCopy
      clientsSubtitleCopy
      clientsAfterCopy
      mobileLogoGrid {
        asset {
          _id
        }
      }
      desktopLogoGrid {
        asset {
          _id
        }
      }
      caseStudiesTitleCopy
      projectCards {
        title
        desktopImage {
          asset {
            _id
          }
          alt
        }
        mobileImage {
          alt
          asset {
            _id
          }
        }
      }
      _rawClosingCopy
      caseStudiesReadMoreCopy
      selectedProjects: caseStudiesHighlighted {
        ... on SanityProject {
          id
          previewImage {
            asset {
              _id
            }
            alt
          }
          title
          descriptor
          slug {
            current
          }
        }
      }
    }
  }
`;
